.app {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  font-family: sans-serif;
}

.app .app-description {
  width: 500px;
  text-justify: inter-word;
}

.app .main-canvas {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.app form {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.05);
}

.app form button[type="submit"] {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  width: fit-content;
}

.app form label {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: bold;
}

.app form input[type="text"],
.app form textarea {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.app form input[type="file"] {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: white;
  margin-bottom: 1rem;
  width: fit-content;
}

.app .preview-window {
  flex-grow: 1;
  width: 100%;
}

.app .preview-window iframe {
  width: 100%;
  height: 100%;
}

.app form #toast {
  color: #00ffaa;
  font-size: 1.25rem;
  color: black;
  padding: 0.5rem;
  border: 1px solid #00ffaa;
  border-radius: 0.25rem;

  position: fixed;
  top: 50px;
  right: 50px;

  transform: translateX(calc(100% + 50px));
  transition: transform 0.5s;
}

.app form #toast.error {
  color: #ff000099;
  border-color: #ff0000;
}

.app form #toast.active {
  transform: translateX(0);
}

.app #generate-form button[type="submit"] {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}
